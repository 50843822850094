import { useState, useContext } from "react";

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { config } from "../Config";
import { Trans } from "../Translations";
import {
  MaterialsData,
  SofaMaterial,
  SofaColor,
  CurrentElements,
} from "./DataTypes";
import TextOptionsChooser from "../TextOptionsChooser";

interface ColorChangeData {
  newColor?: SofaColor;
  newMaterial?: SofaMaterial;
}

export default function MaterialChooser(props: {
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  setCurMaterial: (id: number) => void;
  curColors: { [key: number]: number };
  setCurColors: (newValue: { [key: number]: number }) => void;
  changeColorTo: (newColorId: number, newColorExternalId: string) => void;
  isPopup: boolean;
  curElements: CurrentElements;
  curType: number;
}) {
  const { _ } = useContext(Trans);
  const theme = useTheme();
  const lgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const isPortrait = useMediaQuery("@media (orientation: portrait)");
  const changeColor = function (color: SofaColor): void {
    props.setCurColors({
      ...props.curColors,
      [props.curMaterial]: color.id,
    });
    props.changeColorTo(color.id, color.external_id);
  };
  const changeMaterial = function (material: SofaMaterial): void {
    props.setCurMaterial(material.id);
    const color = material.colors.find(
      (it) => it.id === props.curColors[material.id]
    );
    if (color === undefined) {
      console.error();
      return;
    }
    props.changeColorTo(color.id, color.external_id);
  };
  const displayPopup = function (): boolean {
    let colorId;
    let mixedColor = false;
    for (let element of props.curElements.elements) {
      if (colorId === undefined) {
        colorId = element.c;
      }
      if (colorId !== element.c) {
        mixedColor = true;
        break;
      }
    }
    return mixedColor && !props.isPopup;
  };
  const curColor = props.curColors[props.curMaterial] ?? -1;
  const [pendingColorChange, setPendingColorChange] =
    useState<ColorChangeData>();
  const curMaterial = props.materialsData?.data.find(
    (it) => it.id === props.curMaterial
  );
  if (curMaterial === undefined) {
    console.error();
    return <Box></Box>;
  }
  return (
    <Box>
      <Dialog
        open={pendingColorChange !== undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {_("Change color for all the elements?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_(
              "Do you really wish to change the color of all the elements? You will loose your currently selected colors in the process."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPendingColorChange(undefined)}>
            {_("Cancel")}
          </Button>
          <Button
            onClick={() => {
              const colorChangeData = pendingColorChange;
              if (colorChangeData === undefined) {
                throw Error();
              }
              const material = colorChangeData.newMaterial;
              if (material !== undefined) {
                changeMaterial(material);
              } else {
                const color = colorChangeData.newColor;
                if (color === undefined) {
                  throw Error();
                }
                changeColor(color);
              }
              setPendingColorChange(undefined);
            }}
          >
            {_("Change color")}
          </Button>
        </DialogActions>
      </Dialog>
      <TextOptionsChooser<SofaMaterial>
        mainTitle={_("Choose material")}
        options={
          props.materialsData?.data.filter(
            (it) =>
              it.sofa_types.findIndex(
                (it2) => it2.sofa_types_id === props.curType
              ) !== -1
          ) ?? []
        }
        getTitle={(material) => material.name}
        getId={(material) => material.id}
        selectedOption={curMaterial}
        setSelectedOption={(material) => {
          if (displayPopup()) {
            setPendingColorChange({ newMaterial: material });
          } else {
            changeMaterial(material);
          }
        }}
        isPopup={props.isPopup}
        columns={(isPortrait && !props.isPopup) || lgOrLarger ? 4 : 3}
      />
      {!props.isPopup && (
        <Typography
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: { xs: "0.8em", lg: "1.3em" },
            fontWeight: 600,
            color: "#000",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          {_("Choose color")}
        </Typography>
      )}
      <Box
        sx={{
          marginTop: props.isPopup ? "15px" : "0",
          display: "grid",
          "@media (orientation: portrait)": {
            gridTemplateColumns: "repeat(8, 1fr)",
          },
          "@media (orientation: landscape)": {
            gridTemplateColumns: props.isPopup
              ? "repeat(8, 1fr)"
              : {
                  xs: "repeat(4, 1fr)",
                  lg: "repeat(6, 1fr)",
                  xl: "repeat(8, 1fr)",
                },
          },
          gridTemplateRows: "1fr",
          gridColumnGap: { xs: "3px", lg: "10px" },
          gridRowGap: { xs: "3px", lg: "10px" },
        }}
      >
        {props.materialsData?.data
          .find((it) => it.id === props.curMaterial)
          ?.colors.map((color, colorIndex) => {
            const isSelected = color.id === curColor;
            const borderElement = {
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              borderRadius: "3px",
              content: '""',
            };
            return (
              <Button
                className={`ree-ug-color-button-${colorIndex}`}
                key={color.id}
                variant="outlined"
                disabled={isSelected}
                sx={{
                  minWidth: "inherit",
                  padding: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                  "&.Mui-disabled": {
                    border: "none",
                  },
                  ...(isSelected
                    ? {
                        "::after": {
                          ...borderElement,
                          border: "solid 3px #000",
                        },
                        "::before": {
                          ...borderElement,
                          border: "solid 6px #fff",
                        },
                      }
                    : {
                        ":hover": {
                          border: "none",
                          opacity: 0.7,
                        },
                      }),
                }}
                onClick={() => {
                  if (displayPopup()) {
                    setPendingColorChange({ newColor: color });
                  } else {
                    changeColor(color);
                  }
                }}
              >
                <Box
                  component="img"
                  sx={{
                    pointerEvents: "none",
                    borderRadius: "3px",
                    width: "100%",
                    // height: "100%",
                    // width: "calc(100% - 11px)",
                    // height: "calc(100% - 11px)",
                  }}
                  src={`${config.apiUrl}/assets/${color.icon}`}
                />
              </Button>
            );
          })}
      </Box>
    </Box>
  );
}
