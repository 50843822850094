import { SofaType } from "./DataTypes";

export function curOptionsToExternal(
  curOptions: Array<{ groupId: number; optionId: number }>,
  curType: SofaType
): Array<{ group_external_id: string; option_external_id: string }> {
  return curOptions.map((it) => {
    const outerGroup = curType.options.find(
      (it2) => it.groupId === it2.sofa_options_groups_id.id
    );
    if (outerGroup === undefined) {
      throw Error();
    }
    const group = outerGroup.sofa_options_groups_id;
    const option = group.options.find((it2) => it.optionId === it2.id);
    if (option === undefined) {
      throw Error();
    }
    return {
      group_external_id: group.external_id,
      option_external_id: option.external_id,
    };
  });
}
