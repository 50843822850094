import { useEffect, useState, useRef, useContext } from "react";

import { Button, Box, Typography } from "@mui/material";

import {
  MaterialsData,
  ElementsData,
  CurrentElements,
  SofaType,
} from "./DataTypes";
import { config } from "../Config";
import { Trans } from "../Translations";

export default function Price(props: {
  setLoading: (isLoading: boolean) => void;
  curElements: CurrentElements;
  materialsData: MaterialsData | undefined;
  elementsData: ElementsData | undefined;
  curOptions: Array<{ groupId: number; optionId: number }>;
  curTypeObj: SofaType;
}) {
  const { _ } = useContext(Trans);
  const [price, setPrice] = useState<number>();
  const prevCurElements = useRef<CurrentElements>({ elements: [] });
  const prevOptionsIds = useRef<number[]>([]);
  useEffect(() => {
    let changed = false;
    if (
      prevCurElements.current.elements.length ===
      props.curElements.elements.length
    ) {
      const els1 = prevCurElements.current.elements;
      const els2 = props.curElements.elements;
      for (const el2 of els2) {
        if (els1.findIndex((it) => it.i === el2.i && it.c === el2.c) === -1) {
          changed = true;
          break;
        }
      }
    } else {
      changed = true;
    }
    const optionsIds = props.curOptions.map((it) => it.optionId);
    if (prevOptionsIds.current.length === optionsIds.length) {
      for (let i = 0; i < optionsIds.length; ++i) {
        if (optionsIds[i] !== prevOptionsIds.current[i]) {
          changed = true;
          break;
        }
      }
    } else {
      changed = true;
    }
    if (changed) {
      prevCurElements.current = props.curElements;
      prevOptionsIds.current = optionsIds;
      if (props.curElements.elements.length < 1) {
        setPrice(undefined);
      } else {
        const getApiData = async () => {
          const res = (await fetch(`${config.apiUrl}/sofa-prices`, {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              options: optionsIds,
              elements: props.curElements.elements.map((it) => ({
                i: it.i,
                c: it.c,
              })),
            }),
          }).then((res) => res.json())) as { price: number };
          setPrice(res.price);
        };
        getApiData();
      }
    }
  }, [props]);
  return (
    <Box
      sx={{
        boxShadow: 5,
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (orientation: landscape)": {
          width: {
            xs: "250px",
            lg: "350px",
            xl: "500px",
          },
          height: {
            xs: "50px",
            xl: "95px",
          },
        },
        "@media (orientation: portrait)": {
          width: "100%",
          height: "50px",
        },
      }}
    >
      <Typography
        sx={{
          marginLeft: "15px",
          fontWeight: 600,
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            fontSize: {
              xs: "1em",
              lg: "1.4em",
              xl: "2em",
            },
          },
          "@media (orientation: portrait)": {
            fontSize: "1.5em",
          },
        }}
      >
        {price &&
          new Intl.NumberFormat("sl-SI", {
            style: "currency",
            currency: "EUR",
          }).format(price)}
      </Typography>
      <Button
        onClick={async () => {
          const options = props.curOptions.map((curOption) => {
            const group = props.curTypeObj.options.find(
              (it) => curOption.groupId === it.sofa_options_groups_id.id
            );
            if (group === undefined) {
              throw Error();
            }
            const option = group.sofa_options_groups_id.options.find(
              (it) => it.id === curOption.optionId
            );
            if (option === undefined) {
              throw Error();
            }
            return {
              group_external_id: group.sofa_options_groups_id.external_id,
              external_id: option.external_id,
            };
          });
          props.setLoading(true);
          const res = await fetch("add-to-cart.php", {
            credentials: "same-origin",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              options: options,
              products: props.curElements.elements.map((element) => {
                const eData = props.elementsData;
                const mData = props.materialsData;
                if (eData === undefined || mData === undefined) {
                  throw Error();
                }
                const sofaElement = eData.data.find(
                  (it) => it.id === element.i
                );
                if (sofaElement === undefined) {
                  throw Error();
                }
                let elMaterial;
                let elColor;
                for (const material of mData.data) {
                  for (const color of material.colors) {
                    if (color.id === element.c) {
                      elColor = color.external_id;
                      break;
                    }
                  }
                  if (elColor !== undefined) {
                    elMaterial = material.external_id;
                    break;
                  }
                }
                if (elMaterial === undefined || elColor === undefined) {
                  throw Error();
                }
                return {
                  slug: sofaElement.external_id,
                  material: elMaterial,
                  color: elColor,
                };
              }),
            }),
          });
          console.log(await res.text());
          if (res.ok) {
            window.location.href = "/kosarica";
          }
        }}
        sx={{
          "::after": {
            content: '""',
            marginLeft: "10px",
            width: "20px",
            height: "20px",
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg id='bag' xmlns='http://www.w3.org/2000/svg' width='19.809' height='20' viewBox='0 0 19.809 20'%3E%3Cpath id='rect1979' d='M11.9,292.65a4.013,4.013,0,0,0-4,4v1H3a1,1,0,0,0-1,1v10a4.013,4.013,0,0,0,4,4H17.809a4.013,4.013,0,0,0,4-4v-10a1,1,0,0,0-1-1H15.9v-1A4.013,4.013,0,0,0,11.9,292.65Zm0,2a1.974,1.974,0,0,1,2,2v1h-4v-1A1.974,1.974,0,0,1,11.9,294.65Zm-7.9,5H7.9v2.979a1,1,0,0,0,2,0V299.65h4v2.979a1,1,0,0,0,2,0V299.65h3.9v9a1.974,1.974,0,0,1-2,2H6a1.974,1.974,0,0,1-2-2Z' transform='translate(-2 -292.65)' fill='%23fff' /%3E%3C/svg%3E\")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
          },
          ":hover": {
            backgroundColor: "#000",
          },
          margin: "0 15px",
          color: "white",
          backgroundColor: "#a21318",
          fontWeight: "bold",
          border: "none",
          borderRadius: "7px",
          textTransform: "uppercase",
          transition: "all 0.3s",
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            xs: {
              height: "40px",
              fontSize: "10px",
            },
            xl: {
              height: "60px",
              padding: "20px",
              fontSize: "14px",
            },
          },
          "@media (orientation: portrait)": {
            height: "40px",
            fontSize: "10px",
          },
        }}
      >
        {_("Add to cart")}
      </Button>
    </Box>
  );
}
