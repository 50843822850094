import React, { useEffect, useRef, useState } from "react";
import {
  Config,
  AllSettings,
  PixelStreaming,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.2";
// import Hammer from "hammerjs";

import { client } from "./RpcClient";
import { logEvent } from "./Metrics";

export interface PixelStreamingWrapperProps {
  initialSettings?: Partial<AllSettings>;
}

let loadEndedFired = false;

export const PixelStreamingWrapper = ({
  initialSettings,
}: PixelStreamingWrapperProps) => {
  function mySleep(delay: number) {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  }

  // A reference to parent div element that the Pixel Streaming library attaches into:
  const videoParent = useRef<HTMLDivElement>(null);

  // Pixel streaming library instance is stored into this state variable after initialization:
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming>();

  // A boolean state variable that determines if the Click to play overlay is shown:
  const [clickToPlayVisible, setClickToPlayVisible] = useState(false);

  const [loadingLevel, setLoadingLevel] = useState(true);

  // Run on component mount:
  useEffect(() => {
    let streaming: PixelStreaming | undefined;
    console.log("ree-debug: COMPONENT MOUNT", initialSettings);
    let isFirst = true;
    function myConnect() {
      if (videoParent.current) {
        console.log("ree-debug: COMPONENT CONNECT");
        // Logger.SetLoggerVerbosity(100);
        // Attach Pixel Streaming library to videoParent element:
        const config = new Config({ initialSettings });
        if (streaming !== undefined) {
          streaming.disconnect();
          streaming = undefined;
        }
        streaming = new PixelStreaming(config, {
          videoElementParent: videoParent.current,
        });

        // register a playStreamRejected handler to show Click to play overlay if needed:
        streaming.addEventListener("playStreamRejected", () => {
          setClickToPlayVisible(true);
        });
        streaming.addEventListener(
          "streamerListMessage",
          async ({ data: { messageStreamerList, autoSelectedStreamerId } }) => {
            console.log(
              "STREAMER LIST MESSAGE",
              messageStreamerList,
              autoSelectedStreamerId
            );
            if (isFirst && messageStreamerList.ids.length < 1) {
              console.log("my sleep 2");
              if (streaming !== undefined) {
                streaming.disconnect();
              }
              await mySleep(2000);
              console.log("Reconnecting");
              myConnect();
            } else {
              console.log("Already connected !!!");
              isFirst = false;
            }
          }
        );

        streaming.addEventListener(
          "afkWarningActivate",
          ({ data: { countDown, dismissAfk } }) =>
            console.log("afkWarningActivate")
        );
        streaming.addEventListener(
          "afkWarningUpdate",
          ({ data: { countDown } }) => console.log("afkWarningUpdate")
        );
        streaming.addEventListener("afkWarningDeactivate", () =>
          console.log("afkWarningDeactivate")
        );
        streaming.addEventListener("afkTimedOut", () =>
          console.log("afkTimedOut")
        );
        /*
   streaming.addEventListener("videoEncoderAvgQP", ({ data: { avgQP } }) =>
   console.log("videoEncoderAvgQP")
   );
   */
        streaming.addEventListener("webRtcSdp", () => console.log("webRtcSdp"));
        streaming.addEventListener("webRtcAutoConnect", () =>
          console.log("webRtcAutoConnect")
        );
        streaming.addEventListener("webRtcConnecting", () => {
          console.log("webRtcConnecting");
        });
        streaming.addEventListener("webRtcConnected", () =>
          console.log("webRtcConnected")
        );
        streaming.addEventListener("webRtcFailed", () =>
          console.log("webRtcFailed")
        );
        streaming.addEventListener(
          "webRtcDisconnected",
          ({ data: { eventString, showActionOrErrorOnDisconnect } }) =>
            console.log("webRtcDisconnected")
        );
        streaming.addEventListener("videoInitialized", () =>
          console.log("videoInitialized")
        );
        streaming.addEventListener("streamLoading", () =>
          console.log("streamLoading")
        );
        streaming.addEventListener("playStreamError", ({ data: { message } }) =>
          console.log("playStreamError")
        );
        streaming.addEventListener("playStream", async () => {
          console.log("playStream");
          if (!loadEndedFired) {
            loadEndedFired = true;
            logEvent("load_ended");
          } else {
            logEvent("afk_sleep_end");
          }
          (window as any).eneLoading = true;
          setLoadingLevel(true);
          await client.request("ResetLevel", {
            level_name: "/Game/LoftOffice/Maps/Untitled",
          });
          await (window as any).reeLevelRestore();
          await mySleep(500);
          (window as any).eneLoading = false;
          setLoadingLevel(false);
          logEvent("play_stream");
        });
        streaming.addEventListener(
          "loadFreezeFrame",
          ({ data: { shouldShowPlayOverlay } }) =>
            console.log("loadFreezeFrame")
        );
        /*
   streaming.addEventListener(
   "statsReceived",
   ({ data: { aggregatedStats } }) => console.log("statsReceived")
   );
   */
        streaming.addEventListener(
          "latencyTestResult",
          ({ data: { latencyTimings } }) => console.log("latencyTestResult")
        );
        streaming.addEventListener(
          "streamerListMessage",
          ({ data: { messageStreamerList, autoSelectedStreamerId } }) =>
            console.log("streamerListMessage")
        );
        streaming.addEventListener("settingsChanged", (event) =>
          console.log("settingsChanged")
        );
        streaming.addEventListener("playerCount", ({ data: { count } }) =>
          console.log("playerCount")
        );

        const extWindow = window as any;
        console.log("SETTING CORRECT UI INTERACTION CALLBACK");
        extWindow.emitUIInteraction =
          streaming.emitUIInteraction.bind(streaming);
        streaming.addResponseEventListener(
          "pixel_streaming_action",
          (dataStr) => {
            const data = JSON.parse(dataStr);
            if (extWindow[data.action] !== undefined) {
              extWindow[data.action](data);
            } else if (data.jsonrpc === "2.0") {
              console.log("Calling ree on rpc response data with: ", data);
              extWindow.reeOnRpcResponse(data);
            } else {
              console.error(data);
            }
          }
        );

        // Save the library instance into component state so that it can be accessed later:
        setPixelStreaming(streaming);

        const videoParentUnwrapped = videoParent.current;
        if (videoParentUnwrapped === null) {
          throw Error();
        }

        let hypo: number | undefined = undefined;

        videoParentUnwrapped.addEventListener(
          "touchmove",
          function (event) {
            if (event.targetTouches.length === 2) {
              let hypo1 = Math.hypot(
                event.targetTouches[0].pageX - event.targetTouches[1].pageX,
                event.targetTouches[0].pageY - event.targetTouches[1].pageY
              );
              if (hypo === undefined) {
                hypo = hypo1;
              }
              client.request("SofaCameraZoom", {
                zoom_delta: 0.1 * (hypo1 - hypo),
              });
            }
          },
          false
        );

        videoParentUnwrapped.addEventListener(
          "touchend",
          function (event) {
            hypo = undefined;
          },
          false
        );

        /*
   const hammertime = new Hammer(videoParent.current);
   let lastDistance = 0;
   hammertime.get("pinch").set({ enable: true });
   hammertime.on("pinch", function (ev) {
   if (!ev.isFirst) {
   client.request("SofaCameraZoom", {
zoom_delta: ev.distance - lastDistance,
});
}
lastDistance = ev.distance;
});
*/
      }
    }
    myConnect();
    // Clean up on component unmount:
    return () => {
      try {
        console.log("ree-debug: COMPONENT UNMOUNT");
        if (streaming !== undefined) {
          streaming.disconnect();
          console.log("ree-debug: COMPONENT disconnect");
          streaming = undefined;
        }
      } catch {}
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        id="ree-player"
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={videoParent}
      />
      {clickToPlayVisible && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            pixelStreaming?.play();
            setClickToPlayVisible(false);
          }}
        >
          <div>Click to play</div>
        </div>
      )}
      {loadingLevel && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            zIndex: 1000,
            pointerEvents: "none",
          }}
        />
      )}
    </div>
  );
};
