import { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Switch,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  TextField,
} from "@mui/material";

import {
  ReeRPCExtentData,
  ReeRPCResSofaAdminData,
  ReeSofaBestPosition,
} from "../generated/GeneratedTypes";
import { ElementsData } from "./DataTypes";
import { client } from "../RpcClient";
import { config } from "../Config";

enum DimensionType {
  Min = "min",
  Center = "center",
  Max = "max",
}

enum DimensionIndex {
  X = 0,
  Y = 1,
}
interface Label {
  type: DimensionType;
  index: DimensionIndex;
}

function getFactorFromType(type: DimensionType): number {
  let factor = 1;
  if (type === DimensionType.Min) {
    factor = -1;
  } else if (type === DimensionType.Max) {
    factor = 1;
  } else if (type === DimensionType.Center) {
    factor = 0;
  } else {
    throw Error();
  }
  return factor;
}

function labelToName(lab: Label) {
  return (
    (lab.index === DimensionIndex.X ? "X" : "Y") + " " + lab.type.toUpperCase()
  );
}

function formatDim(x: number) {
  return `${x.toFixed(1)}cm`;
}

function extentToCorners(ex: ReeRPCExtentData): string[] {
  if (ex.origin.length !== 3 || ex.extent.length !== 3) {
    return ["-", "-", "-", "-", "-", "-"];
  }
  const min = [];
  const max = [];
  for (let i = 0; i < 2; ++i) {
    min.push(ex.origin[i] - ex.extent[i]);
    max.push(ex.origin[i] + ex.extent[i]);
  }
  return [
    formatDim(min[0]),
    formatDim(ex.origin[0]),
    formatDim(max[0]),
    formatDim(min[1]),
    formatDim(ex.origin[1]),
    formatDim(max[1]),
  ];
}

export default function ElementDetailsAdmin(props: {
  pointer: number;
  popupClose: () => void;
  elementsData: ElementsData | undefined;
}) {
  const [params] = useSearchParams();
  const [adminData, setAdminData] = useState<ReeRPCResSofaAdminData>();
  const [curBestPositionObj, setCurBestPositionObj] =
    useState<ReeSofaBestPosition>();
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [currentLabel, setCurrentLabel] = useState<string>("");
  const [offset, setOffset] = useState<string>("");
  useEffect(() => {
    const asyncEffect = async () => {
      const data = await client.request("SofaAdminData", {
        pointer: props.pointer,
      });
      setAdminData(data);
      const curBestPosition = data.current_best_position ?? -1;
      if (curBestPosition !== -1) {
        const response = await fetch(
          `${config.apiUrl}/items/sofa_best_positions/${curBestPosition}?fields=*`
        ).then((response) => response.json());
        console.log("SETTING curBestPositionObj to: ", response.data);
        setCurBestPositionObj(response.data);
      }
    };
    asyncEffect();
  }, [props]);
  const isSelectedWidget = (
    <FormControlLabel
      key="is_selected"
      control={
        <Switch
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            await client.request("SofaAdminSetSelected", {
              pointer: props.pointer,
              is_selected: event.target.checked,
            });
            setAdminData(
              await client.request("SofaAdminData", {
                pointer: props.pointer,
              })
            );
          }}
          checked={adminData?.is_selected ?? false}
        />
      }
      label="Is selected"
    />
  );
  const children = [isSelectedWidget];
  const data = adminData;
  if (data !== undefined) {
    const makeLabel = (type: DimensionType, index: DimensionIndex) => {
      return { type, index };
    };
    const labels = [
      makeLabel(DimensionType.Min, DimensionIndex.X),
      makeLabel(DimensionType.Center, DimensionIndex.X),
      makeLabel(DimensionType.Max, DimensionIndex.X),
      makeLabel(DimensionType.Min, DimensionIndex.Y),
      makeLabel(DimensionType.Center, DimensionIndex.Y),
      makeLabel(DimensionType.Max, DimensionIndex.Y),
    ];
    const selectedBounds = extentToCorners(data.selected_element);
    const currentBounds = extentToCorners(data.current_element);
    const rows = [];
    for (let i = 0; i < selectedBounds.length; ++i) {
      const lab = labels[i];
      rows.push({
        name: labelToName(lab),
        sel: selectedBounds[i],
        cur: currentBounds[i],
      });
    }
    const inputs = (
      <TableContainer key="main_table" component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Selected</TableCell>
              <TableCell align="right">Current</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.sel}</TableCell>
                <TableCell align="right">{row.cur}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
    children.push(inputs);
    const input1 = (
      <Box key="form" sx={{ marginTop: "20px" }}>
        <FormControl sx={{ width: "100px" }}>
          <InputLabel id="current-select-label">Current</InputLabel>
          <Select
            value={currentLabel}
            onChange={(event) => setCurrentLabel(event.target.value)}
          >
            {labels.map((label) => {
              const labelName = labelToName(label);
              return (
                <MenuItem
                  key={`mi-cur-${labelName}`}
                  value={JSON.stringify(label)}
                >
                  {labelName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography sx={{ display: "inline-block", margin: "10px" }}>
          =
        </Typography>
        <FormControl sx={{ width: "100px" }}>
          <InputLabel id="selected-select-label">Selected</InputLabel>
          <Select
            value={selectedLabel}
            onChange={(event) => setSelectedLabel(event.target.value)}
          >
            {labels.map((label) => {
              const labelName = labelToName(label);
              return (
                <MenuItem
                  key={`mi-sel-${labelName}`}
                  value={JSON.stringify(label)}
                >
                  {labelName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography sx={{ display: "inline-block", margin: "10px" }}>
          +
        </Typography>
        <TextField
          onChange={(event) => setOffset(event.target.value)}
          value={offset}
          sx={{ width: "100px" }}
          label="Offset"
          variant="outlined"
        />
        <Button
          key="apply_popup"
          sx={{ marginLeft: "20px" }}
          onClick={async () => {
            const cur = JSON.parse(currentLabel);
            const sel = JSON.parse(selectedLabel);
            let selectedValue =
              data.selected_element.origin[sel.index] +
              getFactorFromType(sel.type) *
                data.selected_element.extent[sel.index];
            const new_location = [...data.current_element.actor_origin];
            new_location[cur.index] =
              selectedValue +
              Number(offset) -
              data.current_element.origin[cur.index] +
              data.current_element.actor_origin[cur.index] -
              getFactorFromType(cur.type) *
                data.current_element.extent[cur.index];
            setAdminData(
              await client.request("SofaAdminSetLocation", {
                new_location,
                pointer: props.pointer,
              })
            );
          }}
        >
          Apply
        </Button>
      </Box>
    );
    children.push(input1);
    const curBestPosition = adminData?.current_best_position ?? -1;
    console.log("CUR BEST POSITION", curBestPosition, adminData);
    if (curBestPosition === -1 || curBestPositionObj === undefined) {
      children.push(
        <Button
          key="mark_best_position"
          onClick={async () => {
            const adminKey = params.get("adminKey");
            if (adminKey === null) {
              throw Error();
            }
            await client.request("SofaAdminMarkAsBestPosition", {
              pointer: props.pointer,
              admin_key: adminKey,
            });
            props.popupClose();
          }}
        >
          Mark this as a best position
        </Button>
      );
    } else {
      const curPosition = curBestPositionObj;
      if (curPosition === undefined) {
        throw Error();
      }
      const firstExternalId =
        props.elementsData?.data.find((it) => it.id === curPosition.first_id)
          ?.external_id ?? "UNDEFINED";
      const secondExternalId =
        props.elementsData?.data.find((it) => it.id === curPosition.second_id)
          ?.external_id ?? "UNDEFINED";
      children.push(
        <Box key="pos_div">
          <Typography
            key="pos_title"
            sx={{ display: "inline-block", margin: "10px" }}
          >
            Current best postion (id: {curBestPosition})
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="direction-select-label">
              Best Position Direction
            </InputLabel>
            <Select
              labelId="direction-select-label"
              id="direction-select"
              label="Best Position Direction"
              value={curBestPositionObj?.direction.toString() ?? "0"}
              onChange={async (event) => {
                const adminKey = params.get("adminKey");
                if (adminKey === null) {
                  throw Error();
                }
                console.log(event.target.value);
                const newDirection = Number(event.target.value);
                const response = await fetch(
                  `${config.apiUrl}/items/sofa_best_positions/${curBestPosition}`,
                  {
                    method: "PATCH",
                    body: JSON.stringify({
                      direction: newDirection,
                    }),
                    headers: {
                      Authorization: `Bearer ${adminKey}`,
                      "Content-type": "application/json; charset=UTF-8",
                    },
                  }
                );
                await client.request("SofaAdminRefreshBestPositions", {});

                setCurBestPositionObj({
                  ...curPosition,
                  direction: newDirection,
                });
              }}
            >
              <MenuItem value={"0"}>Any direction</MenuItem>
              <MenuItem value={"1"}>
                {`${secondExternalId} snaps to ${firstExternalId}`}
              </MenuItem>
              <MenuItem value={"-1"}>
                {`${firstExternalId} snaps to ${secondExternalId}`}
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            key="delete_best_position"
            onClick={async () => {
              const adminKey = params.get("adminKey");
              if (adminKey === null) {
                throw Error();
              }
              await client.request("SofaAdminDeleteBestPosition", {
                pointer: props.pointer,
                admin_key: adminKey,
                best_position_id: curBestPosition,
              });
              props.popupClose();
            }}
          >
            Delete this best position
          </Button>
        </Box>
      );
    }
    children.push(
      <Button key="close_popup" onClick={props.popupClose}>
        Close popup
      </Button>
    );
  }
  return <Box>{children}</Box>;
}
