import { MouseEvent, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography, TextField } from "@mui/material";

import { client } from "./RpcClient";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function NumberInput(props: {
  id: string;
  label: string;
  defaultValue: string;
}) {
  const [num, setNum] = useState(props.defaultValue);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  return (
    <TextField
      margin="dense"
      id={props.id}
      label={props.label}
      variant="standard"
      type="text"
      sx={{ display: "block" }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      onChange={(e) => handleChange(e)}
      value={num}
    />
  );
}

export default function AdminRender() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [renderSize, setRenderSize] = useState<{
    width: number;
    height: number;
  }>({ width: 1920, height: 1080 });
  /*
4K: 3840 x 2160,
fullHD: 1920 x 1080
*/
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Render
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            await client.request("SofaRender", renderSize);
            handleClose();
          }}
          disableRipple
        >
          <EditIcon />
          Render
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await client.request("SofaRenderAll", renderSize);
            handleClose();
          }}
          disableRipple
        >
          <FileCopyIcon />
          RenderAll
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={async () => {
            await client.request("SwitchLevel", {
              level_name:
                "/Game/RenderRoom/RenderRoomTransparent2.RenderRoomTransparent2",
            });
            handleClose();
          }}
          disableRipple
        >
          <ArchiveIcon />
          Switch to transparent
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await client.request("SwitchLevel", {
              level_name:
                "/Game/RenderRoom/RenderRoomLevelAdjusted.RenderRoomLevelAdjusted",
            });
            handleClose();
          }}
          disableRipple
        >
          <MoreHorizIcon />
          Switch to studio
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await client.request("SwitchLevel", {
              level_name: "/Game/LoftOffice/Maps/Untitled.Untitled",
            });
            handleClose();
          }}
          disableRipple
        >
          <MoreHorizIcon />
          Switch to normal
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setSettingsOpen(true);
            handleClose();
          }}
          disableRipple
        >
          <MoreHorizIcon />
          Change settings
        </MenuItem>
      </StyledMenu>
      <Dialog open={settingsOpen} onClose={() => setSettingsOpen(false)}>
        <DialogTitle>Output image size</DialogTitle>
        <DialogContent>
          <Typography>5K: 5210 x 2880,</Typography>
          <Typography>4K: 3840 x 2160,</Typography>
          <Typography>fullHD: 1920 x 1080</Typography>
          <NumberInput
            id="resolution_x"
            label="Width"
            defaultValue={renderSize.width.toString()}
          />
          <NumberInput
            id="resolution_y"
            label="Height"
            defaultValue={renderSize.height.toString()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const newWidth = Number(
                (document.getElementById("resolution_x") as HTMLInputElement)
                  .value
              );
              const newHeight = Number(
                (document.getElementById("resolution_y") as HTMLInputElement)
                  .value
              );
              setRenderSize({ width: newWidth, height: newHeight });
              setSettingsOpen(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
