import { useState, useEffect, useContext } from "react";

import { useSearchParams } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

import {
  SofaType,
  UserPreset,
  SofaPreset,
  CurrentElement,
  TypesData,
  MaterialsData,
  CurrentElements,
  ElementsData,
  UserTypes,
  CurPreset,
  SofaElementsUpdateAction,
  UrlStateParam,
} from "./DataTypes";
import PresetChooser from "./PresetChooser";
import Sidebar from "./Sidebar";
import ElementDetails from "./ElementDetails";
import UserGuide from "./UserGuide";
import { client } from "../RpcClient";
import ActionBar from "./ActionBar";
import { config } from "../Config";
import { curOptionsToExternal } from "./Options";
import { Trans } from "../Translations";
import { eneIsLoading } from "../Util";

const localStorageKey = "ree_feydom_user_sofa_types";
const localStorageKeyUserGuide = "ree_show_user_guide";

function getUserTypesFromLocalStorage(): UserTypes {
  return JSON.parse(window.localStorage.getItem(localStorageKey) ?? "{}");
}

export default function SofaIndex(props: {
  setLoading: (isLoading: boolean) => void;
}) {
  const { _, dynTr } = useContext(Trans);
  const [params] = useSearchParams();
  const [elementsData, setElementsData] = useState<ElementsData>();
  const [typesData, setTypesData] = useState<TypesData>();
  const [curType, setCurType] = useState<number>(-1);
  const [curPresets, setCurPresets] = useState<{
    [key: number]: CurPreset;
  }>({});
  const [materialsData, setMaterialsData] = useState<MaterialsData>();
  const [curMaterial, setCurMaterial] = useState<number>(-1);
  const [curColors, setCurColors] = useState<{ [key: number]: number }>({});
  const [curElements, setCurElements] = useState<CurrentElements>({
    elements: [],
  });
  const [userTypes, setUserTypes] = useState<UserTypes>(
    getUserTypesFromLocalStorage()
  );
  const [pendingUserPresetDelete, setPendingUserPresetDelete] =
    useState<UserPreset>();
  const [roomSizeX, setRoomSizeX] = useState(0);
  const [roomSizeY, setRoomSizeY] = useState(0);
  const [roomHardWalls, setRoomHardWalls] = useState(false);
  const updateRoomDimensions = async () => {
    const roomSize = await client.request("SofaRoomSize", {});
    setRoomSizeX(Math.round(roomSize.x));
    setRoomSizeY(Math.round(roomSize.y));
    setRoomHardWalls(roomSize.hard_walls);
  };
  const [curOptions, setCurOptions] = useState<
    Array<{ groupId: number; optionId: number }>
  >([]);
  const [showingCurElements, setShowingCurElements] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [showMeasures, setShowMeasures] = useState<boolean>(false);

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(userTypes));
  }, [userTypes]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    (window as any).eneIsShareLink = params.has("state");
  }, []);
  useEffect(() => {
    const oldDate = window.localStorage.getItem(localStorageKeyUserGuide);
    if (oldDate !== null) {
      const oldTimestamp = Number(oldDate);
      if (Date.now() - oldTimestamp > 7 * 24 * 3600 * 1000) {
        setShowUserGuide(true);
      }
    } else {
      setShowUserGuide(true);
    }
    window.localStorage.setItem(
      localStorageKeyUserGuide,
      Date.now().toString()
    );
  }, [setShowUserGuide]);
  useEffect(() => {
    if (!(window as any).eneIsShareLink && curType !== -1) {
      const path = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      const hash = window.location.hash;
      const state: UrlStateParam = {
        elements: curElements.elements,
        type: curType,
      };
      params.set("state", btoa(JSON.stringify(state)));
      window.history.replaceState(
        {},
        "",
        `${path}?${params.toString()}${hash}`
      );
    }
  }, [curElements, curType]);
  useEffect(() => {
    (window as any).reeShareLinkRestore = async () => {
      if (
        materialsData === undefined ||
        elementsData === undefined ||
        typesData === undefined
      ) {
        throw Error();
      }
      (window as any).eneIsShareLink = false;
      const params = new URLSearchParams(window.location.search);
      const stateAlpha = params.get("state");
      if (stateAlpha === null) {
        throw Error();
      }
      const state = JSON.parse(atob(stateAlpha)) as UrlStateParam;
      const currentTypeObject = typesData.data.find(
        (it) => it.id === state.type
      );
      if (currentTypeObject === undefined) {
        throw Error();
      }
      await onTypeClick(currentTypeObject, true);
      const event = {
        elements: state.elements.map((element) => {
          const curElement = elementsData.data.find(
            (it) => it.id === element.i
          );
          if (curElement === undefined) {
            throw Error();
          }
          let colorExternalId: string | undefined = undefined;
          for (const material of materialsData.data) {
            for (const color of material.colors) {
              if (color.id === element.c) {
                colorExternalId = color.external_id;
                break;
              }
            }
          }
          if (colorExternalId === undefined) {
            throw Error();
          }
          const curState = curElement.states.find((it) => it.id === element.s);
          return {
            ...element,
            external_id: curElement.external_id,
            color_external_id: colorExternalId,
            s: element.s ?? -1,
            state_external_id: curState?.external_id ?? "",
          };
        }),
        options: [], // TODO enable this again: curOptionsToExternal(curOptionsReal, currentTypeObject),
      };
      (window as any).reeWasRestored = true;
      await client.request("SofaRestore", event);
      updateRoomDimensions();
    };
  }, [materialsData, elementsData, typesData]);
  useEffect(() => {
    window.addEventListener("storage", (event) => {
      setUserTypes(getUserTypesFromLocalStorage());
    });
  }, []);
  useEffect(() => {
    const getMaterials = async () => {
      const response: MaterialsData = await fetch(
        `${config.apiUrl}/items/sofa_materials?fields=*,colors.*,sofa_types.sofa_types_id`
      ).then((response) => response.json());
      setMaterialsData(response);
      const newCurColors: { [key: number]: number } = {};
      const materials = response as MaterialsData;
      for (const material of materials.data) {
        newCurColors[material.id] = material.colors[0].id;
      }
      setCurColors(newCurColors);
      setCurMaterial(materials.data[0].id);
    };
    getMaterials();
    const getPresets = async () => {
      const response: TypesData = await fetch(
        `${config.apiUrl}/items/sofa_types?fields=*,translations.*,presets.*,presets.translations.*,options.sofa_options_groups_id.*,options.sofa_options_groups_id.translations.*,options.sofa_options_groups_id.options.*,options.sofa_options_groups_id.options.translations.*&sort=sort`
      ).then((response) => response.json());
      console.log(response);
      if (params.get("adminKey") === null) {
        response.data = response.data.filter(
          (it) => it.only_show_in_admin !== true
        );
      }
      for (const type of response.data) {
        type.presets.sort((it, it2) => it.sort - it2.sort);
      }
      setTypesData(response);
    };
    getPresets();
    const getElements = async () => {
      const response: ElementsData = await fetch(
        `${config.apiUrl}/items/sofa_elements?fields=*,translations.*,states.*,states.translations.*`
      ).then((response) => response.json());
      console.log(response);
      setElementsData(response);
    };
    getElements();
  }, []);
  useEffect(() => {
    (window as any).sofa_render_finished = function (action: {
      filename: string;
    }) {
      console.log("SOFA RENDER FINISHED :)", action);
      const filesDirUrl =
        config.filesServerOverride ?? (window as any).reeFilesUrl;
      if (filesDirUrl === undefined) {
        throw Error();
      }
      const fileUrl = `${filesDirUrl}/${action.filename}`;
      fetch(fileUrl, {})
        .then((res) => res.blob())
        .then((blob) => {
          if (blob != null) {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = action.filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        });
    };
  }, []);
  useEffect(() => {
    (window as any).sofa_elements_data_update = function (
      action: SofaElementsUpdateAction
    ) {
      console.log("DATA UPDATE");
      const extWindow = window as any;
      if (extWindow.reeWasRestored === true) {
        delete extWindow.reeWasRestored;
        console.log("IGNORING THIS CALL");
      } else if (!action.save_change) {
        console.log("INGORING THIS CALL: save_change === false");
      } else {
        const curPreset = curPresets[curType];
        if (curPreset === undefined) {
          console.error();
          return;
        }
        if (curPreset.isUserPreset) {
          const newUserTypes = { ...userTypes };
          const newUserType = newUserTypes[curType];
          if (newUserType === undefined) {
            console.error();
          }
          const currentUserPreset = newUserType.presets.find(
            (it) => it.id === curPreset.id
          );
          if (currentUserPreset === undefined) {
            console.error();
            return;
          }
          currentUserPreset.elements = action.elements;
          setUserTypes(newUserTypes);
        } else {
          if (extWindow.reeWasChangeColorAll === true) {
            delete extWindow.reeWasChangeColorAll;
            console.log("IGNORING THIS CALL 2");
          } else {
            const newUserTypes = { ...userTypes };
            let newUserType = newUserTypes[curType];
            if (newUserType === undefined) {
              newUserType = { maxCount: 0, presets: [] };
              newUserTypes[curType] = newUserType;
            }
            ++newUserType.maxCount;
            const currentTypeObject = typesData?.data.find(
              (it) => it.id === curType
            );
            if (currentTypeObject === undefined) {
              console.error();
              return;
            }
            const newId = Date.now();
            newUserType.presets.push({
              id: newId,
              name:
                _("My") +
                ` ${dynTr(currentTypeObject.translations, (it) => it.name)} ${
                  newUserTypes[curType].maxCount
                }`,
              elements: action.elements,
            });
            setUserTypes(newUserTypes);
            const newCurPresets = { ...curPresets };
            newCurPresets[curType] = { isUserPreset: true, id: newId };
            setCurPresets(newCurPresets);
          }
        }
      }
      setCurElements(action);
    };
  }, [dynTr, curType, curPresets, curElements, userTypes, typesData, _]);
  const curPreset = curPresets[curType];
  const onPresetClick = async (
    preset: SofaPreset,
    curTypeReal: number,
    curOptionsReal: Array<{ groupId: number; optionId: number }>,
    curMaterialReal: number,
    fromRestore: boolean = false
  ) => {
    if (!fromRestore && eneIsLoading()) {
      return;
    }
    setCurPresets({
      ...curPresets,
      [curTypeReal]: {
        isUserPreset: false,
        id: preset.id,
      },
    });
    const elementPositions = JSON.parse(
      preset.element_positions
    ) as CurrentElement[];
    const curColor = materialsData?.data
      ?.find((it) => it.id === curMaterialReal)
      ?.colors.find((it) => it.id === curColors[curMaterialReal]);
    if (curColor === undefined) {
      throw Error();
    }
    const currentTypeObject = typesData?.data.find(
      (it) => it.id === curTypeReal
    );
    if (currentTypeObject === undefined) {
      throw Error();
    }
    const extWindow = window as any;
    extWindow.reeWasRestored = true;
    await client.request("SofaRestore", {
      elements: elementPositions.map((element) => {
        const curElement = elementsData?.data.find((it) => it.id === element.i);
        if (curElement === undefined) {
          throw Error();
        }
        const curState = curElement.states.find((it) => it.id === element.s);
        return {
          ...element,
          external_id: curElement.external_id,
          color_external_id: curColor.external_id,
          c: curColor.id,
          s: element.s ?? -1,
          state_external_id: curState?.external_id ?? "",
        };
      }),
      options: curOptionsToExternal(curOptionsReal, currentTypeObject),
    });
    updateRoomDimensions();
  };
  const onUserPresetClick = async (
    userPreset: UserPreset,
    curTypeReal: number,
    curOptionsReal: Array<{ groupId: number; optionId: number }>,
    fromRestore: boolean = false
  ) => {
    if (!fromRestore && eneIsLoading()) {
      return;
    }
    const currentTypeObject = typesData?.data.find(
      (it) => it.id === curTypeReal
    );
    if (currentTypeObject === undefined) {
      throw Error();
    }
    const event = {
      elements: userPreset.elements.map((element) => {
        const curElement = elementsData?.data.find((it) => it.id === element.i);
        if (curElement === undefined) {
          throw Error();
        }
        let colorExternalId: string | undefined = undefined;
        for (const material of materialsData?.data ?? []) {
          for (const color of material.colors) {
            if (color.id === element.c) {
              colorExternalId = color.external_id;
              break;
            }
          }
        }
        if (colorExternalId === undefined) {
          throw Error();
        }

        const curState = curElement.states.find((it) => it.id === element.s);
        return {
          ...element,
          external_id: curElement.external_id,
          color_external_id: colorExternalId,
          s: element.s ?? -1,
          state_external_id: curState?.external_id ?? "",
        };
      }),
      options: curOptionsToExternal(curOptionsReal, currentTypeObject),
    };
    (window as any).reeWasRestored = true;
    await client.request("SofaRestore", event);
    setCurPresets({
      ...curPresets,
      [curType]: {
        isUserPreset: true,
        id: userPreset.id,
      },
    });
    updateRoomDimensions();
  };
  const onTypeClick = async (type: SofaType, fromRestore: boolean = false) => {
    if (!fromRestore && eneIsLoading()) {
      return;
    }
    const newOptions = [];
    for (let it of type.options) {
      const optionsGroup = it.sofa_options_groups_id;
      if (optionsGroup.options.length > 0) {
        newOptions.push({
          groupId: optionsGroup.id,
          optionId: optionsGroup.options[0].id,
        });
      }
    }
    setCurOptions(newOptions);
    let curMaterialReal = curMaterial;
    if (curMaterial !== -1 && materialsData !== undefined) {
      const filtered = materialsData.data.filter(
        (it) =>
          it.sofa_types.findIndex((it2) => it2.sofa_types_id === type.id) !== -1
      );
      if (filtered.findIndex((it) => it.id === curMaterial) === -1) {
        setCurMaterial(filtered[0].id);
        curMaterialReal = filtered[0].id;
      }
    }
    setCurType(type.id);
    const currentTypeObject = typesData?.data.find((it) => it.id === type.id);
    if (currentTypeObject === undefined) {
      console.error();
      return;
    }
    const curPreset = curPresets[type.id];
    if (curPreset === undefined) {
      onPresetClick(
        currentTypeObject.presets[0],
        type.id,
        newOptions,
        curMaterialReal,
        fromRestore
      );
    } else {
      if (curPreset.isUserPreset) {
        const userType = userTypes[type.id];
        if (userType === undefined) {
          console.error();
          return;
        }
        const currentUserPreset = userType.presets.find(
          (it) => it.id === curPreset.id
        );
        if (currentUserPreset === undefined) {
          console.error();
          return;
        }
        onUserPresetClick(currentUserPreset, type.id, newOptions, fromRestore);
      } else {
        const currentPreset = currentTypeObject.presets.find(
          (it) => it.id === curPreset.id
        );
        if (currentPreset === undefined) {
          console.error();
          return;
        }
        onPresetClick(
          currentPreset,
          type.id,
          newOptions,
          curMaterialReal,
          fromRestore
        );
      }
    }
  };
  useEffect(() => {
    (window as any).reeLevelRestore = async function () {
      const currentTypeObject = typesData?.data.find((it) => it.id === curType);
      if (currentTypeObject !== undefined) {
        // This is wake up from sleep
        await onTypeClick(currentTypeObject, true);
      } else if ((window as any).eneIsShareLink) {
        // New instance from share link
        await (window as any).reeShareLinkRestore();
      }
      await (window as any).reeActionBarRestore();
    };
  }, [onTypeClick]);
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
      }}
    >
      {typesData !== undefined && showUserGuide && (
        <UserGuide curType={curType} setShowUserGuide={setShowUserGuide} />
      )}
      <Dialog
        open={pendingUserPresetDelete !== undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {_("Delete your customized sofa?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_("Do you really wish to delete your customized sofa set") +
              ' "' +
              (pendingUserPresetDelete?.name ?? "") +
              '"'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPendingUserPresetDelete(undefined)}>
            {_("Cancel")}
          </Button>
          <Button
            onClick={() => {
              const userPreset = pendingUserPresetDelete;
              if (userPreset === undefined) {
                console.error();
                return;
              }
              const newUserTypes = { ...userTypes };
              const newUserType = newUserTypes[curType];
              if (newUserType === undefined) {
                console.error();
              }
              const presetIndex = newUserType.presets.findIndex(
                (it) => it.id === userPreset.id
              );
              if (presetIndex === -1) {
                console.error();
                return;
              }
              newUserType.presets.splice(presetIndex, 1);
              setUserTypes(newUserTypes);
              const presets = typesData?.data.find(
                (it) => it.id === curType
              )?.presets;
              if (presets === undefined || presets.length < 1) {
                console.error();
                return;
              }
              onPresetClick(presets[0], curType, curOptions, curMaterial);
              setPendingUserPresetDelete(undefined);
            }}
          >
            {_("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <PresetChooser
        typesData={typesData}
        userTypes={userTypes}
        curType={curType}
        curPreset={curPreset}
        onTypeClick={(type) => onTypeClick(type)}
        onPresetClick={(preset) =>
          onPresetClick(preset, curType, curOptions, curMaterial)
        }
        onUserPresetClick={(userPreset) =>
          onUserPresetClick(userPreset, curType, curOptions)
        }
        onUserPresetDelete={(userPreset) =>
          setPendingUserPresetDelete(userPreset)
        }
        onTypesButtonClick={() => {
          if (eneIsLoading()) {
            return;
          }
          setCurType(-1);
          (window as any).reeWasRestored = true;
          client.request("SofaRestore", {
            elements: [],
            options: [],
          });
        }}
        showCurrentElements={() => {
          setShowingCurElements(true);
        }}
      />
      <Dialog open={showingCurElements}>
        <DialogTitle>Current elements list</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {JSON.stringify(curElements.elements)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowingCurElements(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <Sidebar
        setLoading={props.setLoading}
        materialsData={materialsData}
        curMaterial={curMaterial}
        setCurMaterial={setCurMaterial}
        curColors={curColors}
        setCurColors={setCurColors}
        typesData={typesData}
        curType={curType}
        curPreset={curPreset}
        curElements={curElements}
        elementsData={elementsData}
        userTypes={userTypes}
        setUserTypes={setUserTypes}
        curOptions={curOptions}
        setCurOptions={setCurOptions}
      />
      <ElementDetails
        elementsData={elementsData}
        materialsData={materialsData}
        curMaterial={curMaterial}
        curColors={curColors}
        curElements={curElements}
        curType={curType}
      />
      <ActionBar
        updateRoomDimensions={updateRoomDimensions}
        roomSizeX={roomSizeX}
        roomSizeY={roomSizeY}
        roomHardWalls={roomHardWalls}
        showMeasures={showMeasures}
        setShowMeasures={setShowMeasures}
      />
      {showMeasures && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            padding: "10px 0",
            textAlign: "center",
            "@media (orientation: landscape)": {
              bottom: 0,
              width: {
                xs: "calc(100vw - 250px)",
                lg: "calc(100vw - 350px)",
                xl: "calc(100vw - 500px)",
              },
            },
            "@media (orientation: portrait)": {
              bottom: "calc(30% + 50px)",
              width: "100vw",
            },
            backgroundColor: "#fff8",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {_(
              "Because our products are hand made the dimensions can be ±3%% different than shown above."
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
