import { useContext, useState, useEffect } from "react";

import { DateTime } from "luxon";

import { Box, Typography, TextField, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { client } from "../RpcClient";
import Price from "./Price";
import { Trans } from "../Translations";
import { vB } from "../Style";
import Banner from "../Banner";
import { PergPresetsData, UserPreset, CurPreset } from "./DataTypes";
import Structure from "./Structure";
import Roof from "./Roof";
import SidePannels from "./SidePanels";
import RainGutter from "./RainGutter";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function disableScroll(e: any) {
  e.preventDefault();
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: { xs: 0, lg: "20px 0" } }}>{children}</Box>
      )}
    </div>
  );
}

function allyProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MainContent(props: {}) {
  const { _ } = useContext(Trans);
  const theme = useTheme();
  const lgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const tabs = [
    { title: _("Structure"), widget: () => <Structure /> },
    { title: _("Roof"), widget: () => <Roof /> },
    { title: _("Side panels"), widget: () => <SidePannels /> },
    /*
    { title: _("Rain gutter"), widget: () => <RainGutter /> },
    */
  ];
  const [tabsValue, setTabsValue] = useState(0);
  return (
    <Box
      sx={{
        margin: "0 25px" /*{
          xs: "0 -20px 10px -20px",
          lg: "10px 0 0 0",
        }*/,
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabsValue}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTabsValue(newValue);
            }}
            variant={lgOrLarger ? "fullWidth" : "scrollable"}
            scrollButtons={!lgOrLarger}
            allowScrollButtonsMobile={!lgOrLarger}
            sx={{
              "& .MuiTabScrollButton-root": {
                width: "20px",
              },
              "& .MuiButtonBase-root": {
                xs: {
                  wordBreak: "break-all",
                },
                lg: {
                  padding: "0.3vw",
                  fontSize: "0.6vw",
                },
              },
              minHeight: {
                xs: "24px",
                lg: "48px",
              },
            }}
          >
            {tabs.map((tabData, tabIndex) => {
              return (
                <Tab
                  sx={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: { xs: "10px", lg: "14px" },
                    minHeight: { xs: "24px", lg: "48px" },
                  }}
                  key={tabIndex}
                  label={tabData.title}
                  {...allyProps(tabIndex)}
                />
              );
            })}
          </Tabs>
        </Box>
        {tabs.map((tabData, tabIndex) => {
          return (
            <TabPanel key={tabIndex} value={tabsValue} index={tabIndex}>
              {tabData.widget()}
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
}

export default function Sidebar(props: {
  presetsData: PergPresetsData | undefined;
  userPresets: UserPreset[] | undefined;
  curPreset: CurPreset | undefined;
  setUserPresets: (presets: UserPreset[]) => void;
}) {
  const { _, dynTr } = useContext(Trans);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  const sidebarCss = (realSidebar: boolean) => {
    return {
      pointerEvents: "initial",
      position: "absolute",
      overflowY: "auto",
      "@media (orientation: landscape)": {
        right: 0,
        width: {
          xs: "250px",
          lg: "350px",
          xl: "500px",
        },
        marginBottom: {
          xs: "50px",
          xl: "95px",
        },
        bottom: 0,
        [vB.xs]: {
          top: realSidebar ? 0 : "40px",
        },
        [vB.lg]: {
          top: realSidebar ? 0 : largerThen750 ? "160px" : "40px",
        },
      },
      "@media (orientation: portrait)": {
        right: 0,
        left: 0,
        bottom: 0,
        height: realSidebar ? "30%" : "calc(30% + 50px)",
        marginBottom: realSidebar ? "50px" : 0,
      },
    };
  };
  const isVbLg = useMediaQuery(vB.lg);
  const logoIsShown = !isVbLg || !largerThen750;
  const isLandscape = useMediaQuery("(orientation: landscape)");
  return (
    <Box
      className="ree-ug-sidebar"
      sx={{
        overflow: "hidden",
        ...sidebarCss(true),
      }}
    >
      {logoIsShown && (
        <Box component="a" href="/" onDragStart={(e) => e.preventDefault()}>
          <Box
            component="img"
            onDragStart={(e) => e.preventDefault()}
            sx={{
              margin: "15px",
              marginBottom: 0,
              float: "right",
              height: "20px",
            }}
            src="imgs/afu_logo.svg"
          />
        </Box>
      )}
      <Box sx={{ marginLeft: "25px", marginRight: "25px" }}>
        {(() => {
          const curPreset = props.curPreset;
          if (curPreset !== undefined) {
            if (curPreset.isUserPreset) {
              const curPresetObj = props.userPresets?.find(
                (it) => it.id === curPreset.id
              );
              if (curPresetObj) {
                return (
                  <TextField
                    label={_("Name your CONFIGURATION")}
                    variant="outlined"
                    value={curPresetObj.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newName = event.target.value;
                      const userPresets = props.userPresets;
                      if (userPresets === undefined) {
                        throw Error();
                      }
                      const newUserPresets = { ...userPresets };
                      for (const preset of newUserPresets ?? []) {
                        if (preset.id === curPreset.id) {
                          preset.name = newName;
                          break;
                        }
                      }
                      props.setUserPresets(newUserPresets);
                    }}
                    sx={{
                      marginTop: "15px",
                      color: "#000",
                      "& .MuiInputLabel-root": {
                        fontFamily: '"Montserrat", sans-serif',
                      },
                      "& .MuiOutlinedInput-input": {
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: { xs: "0.9em", lg: "1.5em" },
                        fontWeight: 600,
                        textAlign: "center",
                        height: { xs: "10px", lg: "20px" },
                      },
                    }}
                  />
                );
              }
            } else {
              const curPresetObj = props.presetsData?.data.find(
                (it) => it.id === curPreset.id
              );
              if (curPresetObj) {
                return (
                  <Typography
                    sx={{
                      fontFamily: '"Montserrat", sans-serif',
                      fontSize: { xs: "1em", lg: "2.5em" },
                      fontWeight: 600,
                      marginBottom: "0.3em",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    {dynTr(curPresetObj.translations, (it) => it.name)}
                  </Typography>
                );
              }
            }
          }
          return false;
        })()}
      </Box>
      <MainContent />
      <Banner />
      <Box sx={{ height: "20px" }} />
      <Price />
    </Box>
  );
}
