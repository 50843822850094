import * as React from "react";

import { CircularProgress } from "@mui/material";

import CircularProgressWithLabel from "./CircularProgressWithLabel";

export default function CircularStatic(props: {
  showPercentsAfter?: number;
  time: number;
}) {
  const [progress, setProgress] = React.useState(0);
  const refreshRate = 100;
  const step = 100 / (props.time / refreshRate);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + step;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, refreshRate);
    return () => {
      clearInterval(timer);
    };
  }, [step]);
  const elapsedTime = (progress / 100) * props.time;
  const afterTime = props.showPercentsAfter;
  return afterTime !== undefined && elapsedTime < afterTime ? (
    <CircularProgress />
  ) : (
    <CircularProgressWithLabel value={progress} />
  );
}
