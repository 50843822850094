import { Box, Typography, Button } from "@mui/material";

export default function TextOptionsChooser<Type>(props: {
  mainTitle: string;
  options: Type[];
  getTitle: (option: Type) => string;
  getId: (option: Type) => any;
  selectedOption: Type;
  setSelectedOption: (option: Type) => void;
  isPopup: boolean;
  columns: number;
}) {
  return (
    <Box>
      {!props.isPopup && (
        <Typography
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: { xs: "0.8em", lg: "1.3em" },
            fontWeight: 600,
            color: "#000",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          {props.mainTitle}
        </Typography>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
          gridTemplateRows: "1fr",
          gridColumnGap: { xs: "3px", lg: "10px" },
          gridRowGap: { xs: "3px", lg: "10px" },
        }}
      >
        {props.options.map((option) => {
          const isSelected =
            props.getId(option) === props.getId(props.selectedOption);
          return (
            <Button
              key={props.getId(option)}
              variant="outlined"
              sx={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: { xs: "0.7em", lg: "0.9em" },
                fontWeight: 300,
                textTransform: "none",
                border: "2px solid #d9d9d9",
                borderRadius: "3px",
                backgroundColor: isSelected ? "#000" : "#fff",
                borderColor: isSelected ? "#000" : "#d9d9d9",
                color: "#808080",
                "@media (hover: hover)": {
                  ":hover": {
                    border: "2px solid #808080",
                    backgroundColor: "#fff",
                  },
                },
                "@media (hover: none)": {
                  ":hover": {
                    border: "2px solid #d9d9d9",
                    backgroundColor: "#000",
                    borderColor: "#000",
                  },
                },
                "&.Mui-disabled": {
                  color: "#fff",
                },
              }}
              disabled={isSelected}
              onClick={() => {
                props.setSelectedOption(option);
              }}
            >
              {props.getTitle(option)}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}
