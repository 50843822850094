import { useContext, useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import {
  Box,
  Popover,
  ClickAwayListener,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";

import {
  ElementsData,
  MaterialsData,
  CurrentElements,
  SofaElementState,
} from "./DataTypes";
import { client } from "../RpcClient";
import { config } from "../Config";

import MaterialChooser from "./MaterialChooser";
import ElementDetailsAdmin from "./ElementDetailsAdmin";
import { Trans } from "../Translations";

function StateChoice(props: {
  selected: boolean;
  state: SofaElementState;
  onClick: () => void;
}) {
  const { dynTr } = useContext(Trans);
  return (
    <Button
      key={props.state.id}
      sx={{
        minWidth: "150px",
        padding: 0,
        lineHeight: "1.1em",
        opacity: props.selected ? 1.0 : 0.6,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
        justifyContent: "start",
      }}
      disabled={props.selected}
      onClick={props.onClick}
    >
      {props.state.icon && (
        <Box
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            width: "40px",
            height: "40px",
            backgroundImage: `url('${config.apiUrl}/assets/${props.state.icon}')`,
          }}
        />
      )}
      <Box
        sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
      >
        <Typography
          component="span"
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            textTransform: "none",
            // font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "bold",
            fontSize: "0.9em",
            color: "#000",
            marginTop: "0.3em",
          }}
        >
          {dynTr(props.state.translations, (it) => it.name)}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            textTransform: "none",
            // font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "normal",
            fontSize: "0.85em",
            color: "#333333",
          }}
        >
          {`${Math.round(props.state.length)} x ${Math.round(
            props.state.width
          )}`}
        </Typography>
      </Box>
    </Button>
  );
}

export default function ElementDetails(props: {
  elementsData: ElementsData | undefined;
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  curColors: { [key: number]: number };
  curElements: CurrentElements;
  curType: number;
}) {
  const { dynTr } = useContext(Trans);
  const [popoverData, setPopoverData] = useState<{
    open: boolean;
    position: {
      top: number;
      left: number;
    };
    color: string | undefined;
    pointer: number | undefined;
    element_id: number;
  }>({
    open: false,
    position: { top: 0, left: 0 },
    color: undefined,
    pointer: undefined,
    element_id: -1,
  });
  const [params] = useSearchParams();
  const [curPopupMaterial, setCurPopupMaterial] = useState<number>(-1);
  const [curPopupColors, setCurPopupColors] = useState<{
    [key: number]: number;
  }>({});
  const [curState, setCurState] = useState<number>(-1);
  useEffect(() => {
    (window as any).sofa_element_popup = async function (action: any) {
      console.log("EL POPUP", action);
      const player = document.getElementById("ree-player");
      if (player === null) {
        console.error();
        return;
      }
      const bounds = player.getBoundingClientRect();
      console.log(action);
      const curColorExternalId = action.color as string;
      const newColors: {
        [key: number]: number;
      } = {};
      for (const material of props.materialsData?.data ?? []) {
        newColors[material.id] = props.curColors[material.id];
        for (const color of material.colors) {
          if (color.external_id === curColorExternalId) {
            setCurPopupMaterial(material.id);
            newColors[material.id] = color.id;
          }
        }
      }
      setCurPopupColors(newColors);
      setCurState(
        (
          await client.request("SofaElementState", {
            pointer: action.pointer,
          })
        ).state_id
      );
      setPopoverData({
        open: true,
        position: {
          top: bounds.y + action.mouse_y + 20,
          left: bounds.x + action.mouse_x - 20,
        },
        color: action.color,
        pointer: action.pointer,
        element_id: action.element_id,
      });
    };
  }, [props, params]);
  console.log("POPOVER DATA:", popoverData);
  const pointer = popoverData.pointer;
  const isAdmin = params.get("adminKey") !== null;
  const elementData = props.elementsData?.data.find(
    (it) => it.id === popoverData.element_id
  );
  console.log("ELEMENT DATA:", elementData);
  let dimensionsStr;
  if (elementData !== undefined) {
    const dimObj =
      curState === -1
        ? elementData
        : elementData.states.find((it) => it.id === curState);
    if (dimObj === undefined) {
      console.error();
    } else {
      dimensionsStr = `${Math.round(dimObj.length)} x ${Math.round(
        dimObj.width
      )} x ${Math.round(dimObj.height)} cm`;
    }
  }
  return (
    <Popover
      open={popoverData.open}
      anchorReference="anchorPosition"
      anchorPosition={
        isAdmin ? { left: window.innerWidth, top: 0 } : popoverData.position
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (!isAdmin) {
            setPopoverData({ ...popoverData, open: false });
          }
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "200px",
              lg: isAdmin ? "500px" : "300px",
            },
            // maxHeight: "50vh",
            margin: "20px",
            "@media (orientation: landscape)": {
              maxHeight: "50vh",
            },
            "@media (orientation: portrait)": {
              maxHeight: "30vh",
            },
          }}
        >
          {isAdmin && popoverData.open && pointer !== undefined && (
            <ElementDetailsAdmin
              pointer={pointer}
              popupClose={() => setPopoverData({ ...popoverData, open: false })}
              elementsData={props.elementsData}
            />
          )}
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            {elementData !== undefined && (
              <Typography
                sx={{
                  flexGrow: 1,
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: "1.3em",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                {dynTr(elementData.translations, (it) => it.name)}
              </Typography>
            )}
            <IconButton
              color="primary"
              onClick={() => {
                const pointer = popoverData.pointer;
                if (pointer === undefined) {
                  console.error();
                  return;
                }
                client.request("SofaRotate", {
                  pointer: pointer,
                });
              }}
            >
              <Rotate90DegreesCwIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                const pointer = popoverData.pointer;
                if (pointer === undefined) {
                  console.error();
                  return;
                }
                client.request("SofaDestroy", { pointer: pointer });
                setPopoverData({ ...popoverData, open: false });
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
          {dimensionsStr !== undefined && (
            <Typography
              sx={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "0.8em",
                fontWeight: 400,
                color: "#000",
              }}
            >
              {dimensionsStr}
            </Typography>
          )}
          {pointer !== undefined &&
            elementData !== undefined &&
            elementData.states.length > 0 && (
              <Box sx={{ margin: "10px 0" }}>
                {elementData.states
                  .sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))
                  .map((state) => (
                    <StateChoice
                      selected={curState === state.id}
                      state={state}
                      onClick={async () => {
                        await client.request("SofaSwitchToState", {
                          pointer: pointer,
                          state_external_id: state.external_id,
                          state_id: state.id,
                        });
                        setCurState(state.id);
                      }}
                    />
                  ))}
              </Box>
            )}
          <MaterialChooser
            materialsData={props.materialsData}
            curMaterial={curPopupMaterial}
            setCurMaterial={setCurPopupMaterial}
            curColors={curPopupColors}
            setCurColors={setCurPopupColors}
            changeColorTo={(newColorId, newColorExternalId) => {
              const pointer = popoverData.pointer;
              if (pointer === undefined) {
                console.error();
                return;
              }
              client.request("SofaChangeColor", {
                pointer: pointer,
                color_id: newColorId,
                color_external_id: newColorExternalId,
              });
            }}
            isPopup={true}
            curElements={props.curElements}
            curType={props.curType}
          />
          <Box sx={{ height: "20px" }} />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
}
