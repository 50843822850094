import { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
  TextField,
  ThemeProvider,
  createTheme,
  Button,
} from "@mui/material";

import { config } from "./Config";

interface StandData {
  title: string;
  logo: string;
  description: string;
  links: Array<{
    url: string;
    displayText: string | undefined;
  }>;
}

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#00da99",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

function validateEmail(mail: string) {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export default function StandDetails(props: { standId: number }) {
  const [data, setData] = useState<StandData>();
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  useEffect(() => {
    const getApiData = async () => {
      const response: {
        data: {
          translations: Array<{
            title: string;
            logo: string;
            description: string;
          }>;
          links: Array<{
            url: string;
            translations: Array<{
              display_text: string;
            }>;
          }>;
        };
      } = await fetch(
        `${config.apiUrl}/items/stands/${props.standId}?fields=translations.title,translations.logo,translations.description,links.url,links.translations.display_text`
      ).then((response) => response.json());
      setData({
        title: response.data.translations[0].title,
        logo: response.data.translations[0].logo,
        description: response.data.translations[0].description,
        links: response.data.links.map((link) => ({
          url: link.url,
          displayText: link.translations[0]?.display_text,
        })),
      });
    };
    getApiData();
  }, [props]);
  const url = data
    ? `${config.apiUrl}/assets/${data.logo}?width=160&height=160&fit=inside&format=png`
    : undefined;
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "background.paper",
        }}
      >
        {data ? (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: 160, margin: "40px" }}>
              <img src={url} srcSet={url} alt={data.title} loading="lazy" />
            </Box>
            <Box
              sx={{
                flexGrow: 4,
                marginTop: "70px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h4">{data.title}</Typography>
              <Typography variant="body1" sx={{ marginRight: "70px" }}>
                <div
                  className="ene-stand-details-content"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </Typography>
              <List>
                {data.links.map((link) => {
                  return (
                    <ListItem disablePadding sx={{ justifyContent: "center" }}>
                      <ListItemButton
                        component="a"
                        href={link.url}
                        target="_blank"
                      >
                        <ListItemText
                          primary={
                            link.displayText ? link.displayText + ": " : ""
                          }
                          secondary={
                            <Typography sx={{ color: "#00da99" }}>
                              {link.url}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {submittedEmail ? (
                <Box
                  sx={{
                    alignSelf: "flex-end",
                    marginRight: "70px",
                    marginBottom: "40px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#00da99", display: "inline" }}
                  >
                    Email{" "}
                    <Typography sx={{ color: "black", display: "inline" }}>
                      {submittedEmail}
                    </Typography>{" "}
                    successfully submitted.
                  </Typography>
                </Box>
              ) : (
                <Box
                  component="form"
                  sx={{
                    alignSelf: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "400px",
                    marginRight: "70px",
                    marginBottom: "40px",
                  }}
                >
                  <TextField
                    id="ene-email-entry"
                    fullWidth={true}
                    label="Leave us your email"
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    onClick={async (e) => {
                      e.preventDefault();
                      const email = (
                        document.getElementById("ene-email-entry") as any
                      )?.value;
                      if (!email || !validateEmail(email)) {
                        alert("Wrong / empty email, please try again");
                        return;
                      }
                      await fetch("${config.apiUrl}/items/marseille_emails", {
                        method: "POST",
                        body: JSON.stringify({
                          email: email,
                          stands_id: props.standId,
                        }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      });
                      setSubmittedEmail(email);
                    }}
                    sx={{ marginTop: "30px" }}
                  >
                    Submit email
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              minHeight: 500,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "#00da99" }} />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
