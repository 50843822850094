export const config: {
  apiUrl: string;
  signalsProtocol?: string;
  matchmakerURL?: string;
  filesServerOverride?: string;
  avatarsPortEnding: string;
  signalsOverrideLocal?: string;
  spaceId: string;
  logRocketId: string;
  sentryKey: string;
  disableAnalytics?: boolean;
} = {
  signalsProtocol: "wss://",
  matchmakerURL: "https://matchmaker3.pixels.reeba.io",
  avatarsPortEnding: "2",
  apiUrl: "https://devfeydom.api.reeba.io",
  spaceId: "afu",
  logRocketId: "yiwiio/feydom_designer",
  sentryKey:
    "https://ac3b42e3568d7776aecac75c47700c65@o4506041833619456.ingest.sentry.io/4506041835520000",
  disableAnalytics: true,
};
