import { useState, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Trans } from "../Translations";
import TextOptionsChooser from "../TextOptionsChooser";
import ImageOptionsChooser from "../ImageOptionsChooser";
import { client } from "../RpcClient";

interface GenericOption {
  id: number;
  name: string;
  value: string;
}

export default function Roof(props: {}) {
  const theme = useTheme();
  const lgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const isPortrait = useMediaQuery("@media (orientation: portrait)");
  const { _ } = useContext(Trans);
  const glassThickness = [
    {
      id: 0,
      name: _("8mm"),
      value: "8mm",
    },
    {
      id: 1,
      name: _("10mm"),
      value: "10mm",
    },
  ];
  const glassType = [
    {
      id: 0,
      name: _("Normal glass"),
      value: "normal",
    },
    {
      id: 1,
      name: _("Milk glass"),
      value: "milk",
    },
  ];
  const awningOptions = [
    {
      id: 0,
      name: _("Under roof"),
      value: "under",
    },
    {
      id: 1,
      name: _("On roof"),
      value: "on",
    },
    {
      id: 2,
      name: _("None"),
      value: "none",
    },
  ];
  const colorOptions = [
    {
      id: 0,
      name: "/imgs/afu_color_dark_grey.jpg",
      value: "afu-fabricgrey",
    },
    {
      id: 1,
      name: "/imgs/afu_color_grey.jpg",
      value: "afu-fabricwhite",
    },
  ];
  const [curGlassThickness, setCurGlassThickness] = useState(glassThickness[0]);
  const [curGlassType, setCurGlassType] = useState(glassType[0]);
  const [curAwning, setCurAwning] = useState(awningOptions[0]);
  const [curColor, setCurColor] = useState(colorOptions[0]);
  return (
    <Box>
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Choose glass thickness")}
        options={glassThickness}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={curGlassThickness}
        setSelectedOption={setCurGlassThickness}
        isPopup={false}
        columns={2}
      />
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Choose glass type")}
        options={glassType}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={curGlassType}
        setSelectedOption={(option) => {
          client.request("PergSwitchMaterial", {
            material: option.value === "milk" ? "afu-glass2" : "afu-glass1",
            slot: "Glass",
          });
          setCurGlassType(option);
        }}
        isPopup={false}
        columns={2}
      />
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Awning position")}
        options={awningOptions}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={curAwning}
        setSelectedOption={setCurAwning}
        isPopup={false}
        columns={3}
      />
      {curAwning.value !== "none" && (
        <ImageOptionsChooser<GenericOption>
          mainTitle={_("Choose awning material")}
          options={colorOptions}
          getImage={(option) => option.name}
          getId={(option) => option.id}
          selectedOption={curColor}
          setSelectedOption={(option) => {
            client.request("PergSwitchMaterial", {
              material: option.value,
              slot: "Fabric",
            });
            setCurColor(option);
          }}
          isPopup={false}
        />
      )}
    </Box>
  );
}
