import { useState, useContext, useEffect } from "react";

import { Box, Typography } from "@mui/material";

import ImageOptionsChooser from "../ImageOptionsChooser";
import TextOptionsChooser from "../TextOptionsChooser";
import NumberInput from "../NumberInput";
import { Trans } from "../Translations";
import { client } from "../RpcClient";
import { ReeRPCParamsPergStructureUpdate } from "../generated/GeneratedTypes";
import { PergContext, defaultSidePanel } from "./PergState";
import { arrFindFirst } from "../Util";
import { WritableDraft } from "immer";

interface GenericOption {
  id: number;
  name: string;
  value: string;
}

interface BooleanOption {
  name: string;
  value: boolean;
}

export default function Structure(props: {}) {
  const { _ } = useContext(Trans);
  const freestandingOptions = [
    {
      name: _("Freistehend"),
      value: true,
    },
    {
      name: _("Wandgebunded"),
      value: false,
    },
  ];
  const colorOptions = [
    {
      id: 0,
      name: "/imgs/afu_anthracite.jpg",
      value: "afu-black",
    },
    {
      id: 1,
      name: "/imgs/afu_white.jpg",
      value: "afu-white",
    },
  ];
  const [curColor, setCurColor] = useState(colorOptions[0]);
  const { structure, setStructure } = useContext(PergContext);
  function getMinH(heightFront: number, depth: number) {
    const minH =
      14 + heightFront + Math.tan((4 * Math.PI) / 180) * (depth - 14);
    return minH > 400 ? 400 : minH;
  }
  function getMaxH(heightFront: number, depth: number) {
    const maxH =
      14 + heightFront + Math.tan((15 * Math.PI) / 180) * (depth - 14);
    return maxH > 400 ? 400 : maxH;
  }
  (window as any).reeLevelRestore = async function () {
    console.log(
      "reeLevelRestore() called in pergola index. restoring some stuff."
    );
    client.request("PergSwitchMaterial", {
      material: curColor.value,
      slot: "Panel",
    });
    client.request("PergStructureUpdate", structure);
  };
  const ensurePanelsCount = (
    draft: WritableDraft<ReeRPCParamsPergStructureUpdate>,
    position: string,
    count: number
  ) => {
    let validCount = 0;
    for (let idx = draft.side_panels.length - 1; idx >= 0; --idx) {
      if (draft.side_panels[idx].position === position) {
        if (draft.side_panels[idx].sub_pos >= count) {
          draft.side_panels.splice(idx, 1);
        } else {
          ++validCount;
        }
      }
    }
    const order = ["left", "front", "right", "back"];
    if (validCount < count) {
      let insertPosition = 0;
      for (let idx = draft.side_panels.length - 1; idx >= 0; --idx) {
        if (draft.side_panels[idx].position === position) {
          insertPosition = idx + 1;
          break;
        } else if (
          order.indexOf(draft.side_panels[idx].position) <
          order.indexOf(position)
        ) {
          insertPosition = idx + 1;
          break;
        }
      }
      const newData = [];
      for (let idx = validCount; idx < count; ++idx) {
        newData.push(defaultSidePanel(position, idx));
      }
      draft.side_panels.splice(insertPosition, 0, ...newData);
    }
  };
  const ensurePanelsCountWidth = (
    draft: WritableDraft<ReeRPCParamsPergStructureUpdate>
  ) => {
    ensurePanelsCount(
      draft,
      "back",
      draft.freestanding ? Math.ceil(draft.width / 600) : 0
    );
    ensurePanelsCount(
      draft,
      "front",
      Math.ceil(draft.width / (draft.strong_front ? 600 : 400))
    );
  };
  return (
    <Box>
      <TextOptionsChooser<BooleanOption>
        mainTitle={_("Aufbau")}
        options={freestandingOptions}
        getTitle={(option) => option.name}
        getId={(option) => option.value}
        selectedOption={arrFindFirst(
          freestandingOptions,
          (it) => it.value === structure.freestanding
        )}
        setSelectedOption={(option) => {
          setStructure((draft) => {
            draft.freestanding = option.value;
            ensurePanelsCountWidth(draft);
          });
        }}
        isPopup={false}
        columns={2}
      />
      <ImageOptionsChooser<GenericOption>
        mainTitle={_("Choose color of structure")}
        options={colorOptions}
        getImage={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={curColor}
        setSelectedOption={(option) => {
          client.request("PergSwitchMaterial", {
            material: option.value,
            slot: "Panel",
          });
          setCurColor(option);
        }}
        isPopup={false}
      />
      <NumberInput
        title={_("Breite")}
        min={0.5}
        max={15.0}
        step={0.01}
        value={structure.width / 100}
        onChange={(val) =>
          setStructure((draft) => {
            draft.width = val * 100;
            ensurePanelsCountWidth(draft);
          })
        }
      />
      <NumberInput
        title={_("Tiefe")}
        min={0.5}
        max={5.0}
        step={0.01}
        value={structure.depth / 100}
        onChange={(val) =>
          setStructure((draft) => {
            draft.depth = val * 100;
            const backMin = getMinH(draft.height_front, draft.depth);
            const backMax = getMaxH(draft.height_front, draft.depth);
            if (draft.height_back > backMax) {
              draft.height_back = backMax;
            } else if (draft.height_back < backMin) {
              draft.height_back = backMin;
            }
          })
        }
      />
      <NumberInput
        title={_("Durchgangshöhe")}
        min={0.5}
        max={3.52}
        step={0.01}
        value={structure.height_front / 100}
        onChange={(val) =>
          setStructure((draft) => {
            draft.height_front = val * 100;
            const backMin = getMinH(draft.height_front, draft.depth);
            const backMax = getMaxH(draft.height_front, draft.depth);
            if (draft.height_back > backMax) {
              draft.height_back = backMax;
            } else if (draft.height_back < backMin) {
              draft.height_back = backMin;
            }
          })
        }
        subtitle={
          _("Dachneigung: ") +
          Math.round(
            (Math.atan(
              (structure.height_back - structure.height_front - 14) /
                (structure.depth - 14)
            ) *
              180) /
              Math.PI
          ) +
          _("° (von 4° bis 15°)")
        }
      />
      <NumberInput
        title={_("Gesamthöhe")}
        min={getMinH(structure.height_front, structure.depth) / 100}
        max={getMaxH(structure.height_front, structure.depth) / 100}
        step={0.01}
        value={structure.height_back / 100}
        onChange={(val) =>
          setStructure((draft) => {
            draft.height_back = val * 100;
          })
        }
      />
    </Box>
  );
}
