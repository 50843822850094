import { config } from "./Config";

function makeId(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const clientId = makeId(20);

async function logEventAsync(name: string, value?: string) {
  try {
    console.log("EVENT:", name, value);
    const response = await fetch(`${config.apiUrl}/simple-metrics`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        space_id: config.spaceId,
        client_id: clientId,
        name: name,
        value: value ?? "",
      }),
    });
    const data = await response.json();
    if (data.s !== 0) {
      console.error("Endpoint returned not 0 status");
    }
  } catch (err) {
    console.error(err);
  }
}

export function logEvent(name: string, value?: string) {
  if (config.disableAnalytics !== true) {
    logEventAsync(name, value);
  }
}
