import { useState, useContext, useEffect } from "react";

import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Trans } from "../Translations";

enum Direction {
  Up,
  Down,
  Left,
  Right,
}

function Tip(props: {
  direction: Direction;
  title: string;
  description: string;
  spotlight: { top: number; left: number; width: number; height: number };
  onClose: () => void;
}) {
  const smallerThen450 = useMediaQuery("(max-width:450px)");
  let cssSides: string[] = [];
  let cssPos: string[] = [];
  let cssReverse = "";
  let cssAnchor: SxProps<Theme> = {};
  const margin = 20;
  const padding = smallerThen450 ? 10 : 16;
  const realWidth = smallerThen450 ? 300 : 350;
  const width = realWidth + 2 * padding;
  const arrowSize = 16;
  let anchorTop = "";
  let anchorLeft = "";
  if (props.direction === Direction.Up) {
    cssSides = ["Left", "Right"];
    cssReverse = "Bottom";
    cssPos = ["top", "left"];
    cssAnchor = {
      top:
        (
          props.spotlight.top +
          props.spotlight.height +
          arrowSize +
          margin
        ).toString() + "px",
      left:
        (
          props.spotlight.left +
          props.spotlight.width / 2 -
          width / 2
        ).toString() + "px",
    };
  } else if (props.direction === Direction.Down) {
    cssSides = ["Left", "Right"];
    cssReverse = "Top";
    cssPos = ["bottom", "left"];
    cssAnchor = {
      top: (props.spotlight.top - arrowSize - margin).toString() + "px",
      left:
        (
          props.spotlight.left +
          props.spotlight.width / 2 -
          width / 2
        ).toString() + "px",
      transform: "translateY(-100%)",
    };
  } else if (props.direction === Direction.Left) {
    cssSides = ["Top", "Bottom"];
    cssReverse = "Right";
    cssPos = ["left", "top"];
    cssAnchor = {
      // TODO(jakgra)
      top: 0,
      left: 0,
    };
  } else if (props.direction === Direction.Right) {
    cssSides = ["Top", "Bottom"];
    cssReverse = "Left";
    cssAnchor = {
      top: (props.spotlight.top + props.spotlight.height / 2).toString() + "px",
      left:
        (props.spotlight.left - width - arrowSize - margin).toString() + "px",
      transform: "translateY(-50%)",
    };
    cssPos = ["right", "top"];
  }
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1501,
        width: realWidth + "px",
        backgroundColor: "white",
        borderRadius: "5px",
        boxShadow: "0 0 5px 2px rgba(0,0,0,0.2)",
        padding: padding + "px",
        ...cssAnchor,
      }}
    >
      <IconButton
        sx={{ pointerEvents: "all", position: "absolute", right: 0, top: 0 }}
        aria-label="close"
        size="large"
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          position: "absolute",
          [cssPos[0]]: "-" + arrowSize + "px",
          [cssPos[1]]: "calc(50% - " + arrowSize + "px)",
          width: 0,
          height: 0,
          ["border" + cssSides[0]]: arrowSize + "px solid transparent",
          ["border" + cssSides[1]]: arrowSize + "px solid transparent",
          ["border" + cssReverse]: arrowSize + "px solid white",
        }}
      />
      <Typography
        sx={{
          margin: "0px",
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
          color: "black",
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "1.3em",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          margin: "16px 10px",
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
          color: "black",
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "0.8em",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
}

function Spotlight(props: {
  position: { top: number; left: number; width: number; height: number };
  margin: number;
}) {
  const pos = props.position;
  const margin = props.margin;
  const left = pos.left - margin;
  const top = pos.top - margin;
  const right = pos.left + pos.width + margin;
  const bottom = pos.top + pos.height + margin;
  const css = {
    position: "absolute",
    overflow: "hidden",
    pointerEvents: "all",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };
  return (
    <Box
      className="neki"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: 1500,
        backgroundColor: "transparent",
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          top: top,
          left: 0,
          width: left + "px",
          height: (pos.height + 2 * margin).toString() + "px",
          ...css,
        }}
        onClick={() => {}}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100vw",
          height: (pos.top - margin).toString() + "px",
          ...css,
        }}
        onClick={() => {}}
      />
      <Box
        sx={{
          top: top,
          left: right + "px",
          right: 0,
          height: (pos.height + 2 * margin).toString() + "px",
          ...css,
        }}
        onClick={() => {}}
      />
      <Box
        sx={{
          top: bottom + "px",
          left: 0,
          width: "100vw",
          height:
            "calc(100vh - " +
            (pos.top + pos.height + margin).toString() +
            "px)",
          ...css,
        }}
        onClick={() => {}}
      />
    </Box>
  );
}

export default function UserGuide(props: {
  curType: number;
  setShowUserGuide: (show: boolean) => void;
}) {
  const { _ } = useContext(Trans);
  const [spotlight, setSpotlight] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  }>();
  const isLandscape = useMediaQuery("(orientation: landscape)");
  useEffect(() => {
    const maybeHide = function () {
      if (props.curType !== -1) {
        props.setShowUserGuide(false);
      }
    };
    // Equivalent to componentDidMount
    document.addEventListener("mousedown", maybeHide);
    document.addEventListener("touchstart", maybeHide);

    // Equivalent to componentWillUnmount
    return () => {
      document.removeEventListener("mousedown", maybeHide);
      document.removeEventListener("touchstart", maybeHide);
    };
  }, [props]);
  useEffect(() => {
    const spotlightDivs = document.getElementsByClassName(
      props.curType === -1 ? "ree-ug-types" : "ree-ug-sidebar"
    );
    if (spotlightDivs.length < 1) {
      console.error("Couldn't find spotlight division");
      return;
    }
    const spotlightDiv = spotlightDivs[0];
    const onResizeCallback = () => {
      const spotlightRect = spotlightDiv.getBoundingClientRect();
      if (
        spotlight === undefined ||
        spotlight.width !== spotlightRect.width ||
        spotlight.height !== spotlightRect.height ||
        spotlight.top !== spotlightRect.top ||
        spotlight.left !== spotlightRect.left
      ) {
        setSpotlight({
          top: spotlightRect.top,
          left: spotlightRect.left,
          width: spotlightRect.width,
          height: spotlightRect.height,
        });
      }
    };
    onResizeCallback();
    const resizeObserver = new ResizeObserver(onResizeCallback);
    resizeObserver.observe(spotlightDiv);
    return () => {
      try {
        resizeObserver.disconnect();
      } catch {}
    };
  }, [setSpotlight, props]);
  if (spotlight === undefined) {
    return <Box sx={{ width: 0, height: 0, position: "absolute" }} />;
  }
  return (
    <Box>
      <Spotlight margin={props.curType === -1 ? 5 : 0} position={spotlight} />
      <Tip
        direction={
          props.curType === -1
            ? Direction.Up
            : isLandscape
            ? Direction.Right
            : Direction.Down
        }
        spotlight={spotlight}
        title={
          props.curType === -1
            ? _("Select one of the models")
            : _("Add elements")
        }
        description={
          props.curType === -1
            ? _("Sofas you build will be saved on the right side of this bar")
            : _("Drag & drop elements and click on attributes to change them")
        }
        onClose={() => {
          props.setShowUserGuide(false);
        }}
      />
    </Box>
  );
}
