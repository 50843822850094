import { useContext, useState, useEffect } from "react";

import { Box } from "@mui/material";

import { BannersData, BannerData } from "./DataTypes";
import { config } from "./Config";
import { Trans } from "./Translations";

export default function Banner(props: {}) {
  const { dynTr } = useContext(Trans);
  const [banner, setBanner] = useState<BannerData>();
  useEffect(() => {
    const getBannersData = async () => {
      const bannersData: BannersData = await fetch(
        `${config.apiUrl}/items/banners?fields=*,translations.*`
      ).then((response) => response.json());
      console.log("GOT banners");
      console.log(bannersData);
      if (bannersData.data.length > 0) {
        setBanner(bannersData.data[0]);
      }
      /*
         for (banner of bannersData.data) {
         let validFrom = false;
         if (banner.valid_from == null) {
         validFrom = true;
         } else {
         validFrom = DateTime.fromISO(banner.valid_from);
         }
         setBanner(bannersData.data[0]);
         break;
         }
         */
    };
    getBannersData();
  }, []);
  return banner === undefined ? (
    <Box />
  ) : (
    <Box
      component="a"
      href={dynTr(banner.translations, (it) => it.url)}
      target="_blank"
      onDragStart={(e) => e.preventDefault()}
      sx={{
        display: "block",
        margin: "25px",
        position: "relative",
        width: "calc(100% - 50px)",
      }}
    >
      <Box
        component="img"
        onDragStart={(e) => e.preventDefault()}
        sx={{
          width: "100%",
        }}
        src={dynTr(
          banner.translations,
          (it) => `${config.apiUrl}/assets/${it.image}`
        )}
      />
    </Box>
  );
}
