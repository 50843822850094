import { useState, useContext } from "react";

import { Box, Typography, Button } from "@mui/material";

import { Trans } from "./Translations";

export default function ImageOptionsChooser<Type>(props: {
  mainTitle: string;
  options: Type[];
  getImage: (option: Type) => string;
  getId: (option: Type) => any;
  selectedOption: Type;
  setSelectedOption: (option: Type) => void;
  isPopup: boolean;
}) {
  const { _ } = useContext(Trans);
  return (
    <Box
      sx={{
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          fontFamily: '"Montserrat", sans-serif',
          fontSize: { xs: "0.8em", lg: "1.3em" },
          fontWeight: 600,
          color: "#000",
          marginTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        {props.mainTitle}
      </Typography>
      <Box
        sx={{
          marginTop: props.isPopup ? "15px" : "0",
          display: "grid",
          "@media (orientation: portrait)": {
            gridTemplateColumns: "repeat(8, 1fr)",
          },
          "@media (orientation: landscape)": {
            gridTemplateColumns: props.isPopup
              ? "repeat(8, 1fr)"
              : {
                  xs: "repeat(4, 1fr)",
                  lg: "repeat(6, 1fr)",
                  xl: "repeat(8, 1fr)",
                },
          },
          gridTemplateRows: "1fr",
          gridColumnGap: { xs: "3px", lg: "10px" },
          gridRowGap: { xs: "3px", lg: "10px" },
        }}
      >
        {props.options.map((option, optionIndex) => {
          const isSelected =
            props.getId(option) === props.getId(props.selectedOption);
          const borderElement = {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: "3px",
            content: '""',
          };

          return (
            <Button
              className={`ree-ug-color-button-${optionIndex}`}
              key={props.getId(option)}
              variant="outlined"
              disabled={isSelected}
              sx={{
                minWidth: "inherit",
                padding: 0,
                width: "100%",
                height: "100%",
                border: "none",
                "&.Mui-disabled": {
                  border: "none",
                },
                ...(isSelected
                  ? {
                      "::after": {
                        ...borderElement,
                        border: "solid 3px #000",
                      },
                      "::before": {
                        ...borderElement,
                        border: "solid 6px #fff",
                      },
                    }
                  : {
                      ":hover": {
                        border: "none",
                        opacity: 0.7,
                      },
                    }),
              }}
              onClick={() => {
                props.setSelectedOption(option);
              }}
            >
              <Box
                component="img"
                sx={{
                  pointerEvents: "none",
                  borderRadius: "3px",
                  width: "100%",
                  // height: "100%",
                  // width: "calc(100% - 11px)",
                  // height: "calc(100% - 11px)",
                }}
                src={props.getImage(option)}
              />
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}
