import React from "react";
import ReactDOM from "react-dom";

/*
   import "@fontsource/barlow/300.css";
   import "@fontsource/barlow/400.css";
   import "@fontsource/barlow/500.css";
   import "@fontsource/barlow/700.css";
   import "@fontsource/barlow/800.css";
   */

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

import "overlayscrollbars/css/OverlayScrollbars.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { polyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";
import Map from "./Map";
import StandDetails from "./StandDetails";
import reportWebVitals from "./reportWebVitals";
import AppList from "./AppList";
import { config } from "./Config";
import { wpx } from "./Util";
import Translations from "./Translations";
import { clientId, logEvent } from "./Metrics";

// options are optional ;)
polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: (e) => {
    e.preventDefault();
  },
});

let extWindow = window as any;
extWindow.reeConfig = config;

extWindow.ene_render_exhibitors_list = function () {
  ReactDOM.render(
    <React.StrictMode>
      <Map />
    </React.StrictMode>,
    document.getElementById("ree_popup_root")
  );
};
// extWindow.ene_render_exhibitors_list();

extWindow.ree_render_stand_details = function (id: number) {
  ReactDOM.render(
    <React.StrictMode>
      <StandDetails standId={id} />
    </React.StrictMode>,
    document.getElementById("ree_popup_root")
  );
};
// extWindow.ene_render_stand_details(18);

async function main() {
  if (config.disableAnalytics !== true) {
    Sentry.init({
      dsn: config.sentryKey,
    });
    LogRocket.init(config.logRocketId);
    LogRocket.identify(clientId);
    setupLogRocketReact(LogRocket);
  }
  logEvent("load_started");
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00dc9b",
        contrastText: "#414042",
        dark: "#00b09f",
        light: "#00dc9b",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#414042",
        secondary: "#414042",
        disabled: "#414042",
      },
    },
    typography: {
      fontFamily: "Montserrat",
      h1: {
        fontWeight: 800,
        fontSize: wpx(28),
        lineHeight: 1,
        letterSpacing: "normal",
        textTransform: "uppercase",
        color: "#414042",
      },
      h2: {
        fontWeight: 800,
        fontSize: wpx(20),
        lineHeight: 1,
        letterSpacing: "normal",
        textTransform: "uppercase",
        color: "#414042",
      },
    },
  });
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Translations>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              {/*<Route path="/:appId" element={<App />} />*/}
              <Route path="/" element={<App />} />
            </Routes>
          </BrowserRouter>
        </Translations>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

extWindow.current_popup = null;
extWindow.ree_open_iframe = function (url: string) {
  extWindow.current_popup = extWindow.GLightbox({
    width: "400px",
    height: "300px",
    elements: [
      {
        content:
          "<div style='text-align: center; margin-top: 20px;'>" +
          "<p>The external site will open in a new browser tab / window.<br>To return to Envision Expo navigate back to this tab / window.</p>" +
          "<button type='button' onclick='window.open(\"" +
          url +
          "\"); window.current_popup.close(); window.current_popup = null;'>GO TO EXTERNAL SITE</button>" +
          "</div>",
      },
    ],
  });
  extWindow.current_popup.open();
};
extWindow.ree_open_pdf = function (url: string) {
  const popup = extWindow.GLightbox({
    width: "90vw",
    height: "90vh",
    elements: [
      {
        content:
          '<iframe width="100%" height="100%" src="' + url + '"></iframe>',
      },
    ],
  });
  popup.open();
};
extWindow.ree_open_video = function (url: string) {
  const popup = extWindow.GLightbox({
    width: "90vw",
    height: "90vh",
    autoplayVideos: true,
    elements: [
      {
        href: url + "?.mp4",
        type: "video",
        source: "local", // vimeo, youtube or local
        width: "90vw",
      },
    ],
  });
  popup.open();
  return popup;
};
extWindow.ree_open_stand = function (id: number) {
  extWindow.current_popup = extWindow.GLightbox({
    width: "90vw",
    height: "90vh",
    elements: [
      {
        content: '<div id="ree_popup_root"></div>',
      },
    ],
  });
  extWindow.current_popup.open();
  extWindow.setTimeout(function () {
    extWindow.ree_render_stand_details(id);
  }, 300);
};
