function roundString(pixels: number, divideBy: number, suffix: string) {
  const percents = (pixels / divideBy) * 100;
  const rounded = Math.round(percents * 1000) / 1000;
  return `${rounded}${suffix}`;
}

export function wpx(pixels: number): string {
  return roundString(pixels, 1920, "vw");
}

export function hpx(pixels: number): string {
  return roundString(pixels, 1080, "vh");
}

export function isPixels(): boolean {
  const extWindow = window as any;
  return extWindow.ue === undefined;
}

export function eneIsLoading(): boolean {
  return (window as any).eneLoading === true;
}

export function arrFindFirst<T>(array: T[], predicate: (it: T) => boolean): T {
  const item = array.find(predicate);
  if (item === undefined) {
    throw Error("findFirst() element not found in array");
  }
  return item;
}
