import { useEffect, useState, useRef, useContext } from "react";

import { Button, Box, Typography } from "@mui/material";

import { Trans } from "../Translations";

export default function Price(props: {}) {
  const { _ } = useContext(Trans);
  const [price, setPrice] = useState(12000);
  return (
    <Box
      sx={{
        boxShadow: 5,
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (orientation: landscape)": {
          width: {
            xs: "250px",
            lg: "350px",
            xl: "500px",
          },
          height: {
            xs: "50px",
            xl: "95px",
          },
        },
        "@media (orientation: portrait)": {
          width: "100%",
          height: "50px",
        },
      }}
    >
      <Typography
        sx={{
          marginLeft: "15px",
          fontWeight: 600,
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            fontSize: {
              xs: "1em",
              lg: "1.4em",
              xl: "2em",
            },
          },
          "@media (orientation: portrait)": {
            fontSize: "1.5em",
          },
        }}
      >
        {
          _("Request quote:") /*price &&
          new Intl.NumberFormat("sl-SI", {
            style: "currency",
            currency: "EUR",
          }).format(price)*/
        }
      </Typography>
      <Button
        href="mailto:kontakt@af-ueberdachung.de"
        // onClick={async () => alert("Coming soon")}
        sx={{
          ":hover": {
            backgroundColor: "#000",
          },
          margin: "0 15px",
          color: "white",
          backgroundColor: "#04dceb",
          fontWeight: "bold",
          border: "none",
          borderRadius: "7px",
          textTransform: "uppercase",
          transition: "all 0.3s",
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            xs: {
              height: "40px",
              fontSize: "10px",
            },
            xl: {
              height: "60px",
              padding: "20px",
              fontSize: "14px",
            },
          },
          "@media (orientation: portrait)": {
            height: "40px",
            fontSize: "10px",
          },
        }}
      >
        {_("Inquire")}
      </Button>
    </Box>
  );
}
